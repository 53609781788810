/** @jsx jsx */
import type { FC, ReactNode } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';
import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import VisuallyHidden from '@atlaskit/visually-hidden';

import { getElementBounds } from '@confluence/dom-helpers';

import { popupPortalContainerId } from './HighlightActionsProvider';
import { ButtonContainer } from './highlightActionsStyled';

const popupPanelStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes popup': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'0%': {
			opacity: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'100%': {
			opacity: 1,
		},
	},
	position: 'absolute',
	animation: `popup 100ms forwards linear`,
});

const popupTransition = css({
	transition: 'top 100ms ease-out, left 100ms ease-out',
});

const i18n = defineMessages({
	toolbarAlert: {
		id: 'highlight-actions.toolbar.alert',
		defaultMessage: 'Floating toolbar is available. Press tab to access it',
		description:
			'Alert to announce to screenreader users how to access the floating toolbar with the keyboard',
	},
});

type PopupProps = {
	children: ReactNode;
	top: number;
	left: number;
	needTransition?: boolean;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
};

export const Popup: FC<PopupProps> = ({
	children,
	top,
	left,
	needTransition = true,
	onMouseLeave,
	onMouseEnter,
}) => {
	const WINDOW_PADDING = 20;
	const popupRef = useRef(null);
	const [popupLeft, setPopupLeft] = useState(left);
	const { formatMessage } = useIntl();

	useLayoutEffect(() => {
		if (!popupRef.current) return;

		const { left: elementLeft, right } = getElementBounds(popupRef.current!);
		const windowWidth = window.innerWidth;

		// WS-1703 - If the popup menu is off either side of the screen, set it so it's inside the WINDOW_PADDING
		if (elementLeft < 0) {
			setPopupLeft(left - elementLeft + WINDOW_PADDING);
		} else if (right > windowWidth) {
			setPopupLeft(left - (right - windowWidth) - WINDOW_PADDING);
		} else {
			setPopupLeft(left);
		}
	}, [left]);

	const portalContainer = document.querySelector(`#${popupPortalContainerId}`);
	const mouseMoveHandlers = onMouseEnter && onMouseLeave && { onMouseEnter, onMouseLeave };

	useEffect(() => {
		const popupPortal = document.getElementById(popupPortalContainerId);
		if (!popupPortal) return;

		const screenreaderCb = (_mutationsList, observer) => {
			//These accessibility attributes are added dynamically here rather than directly on the VisuallyHidden element because it did not get read by the NVDA screenreader properly on Firefox. This is due to the screenreader sometimes only reading content when there has been a dynamic change to an element
			const accessibleDiv = document.getElementById('toolbar-alert');
			if (accessibleDiv instanceof HTMLElement) {
				accessibleDiv?.setAttribute('role', 'alert');
				accessibleDiv?.setAttribute('aria-live', 'polite');
				observer.disconnect();
			}
		};

		const observer = new MutationObserver(screenreaderCb);
		observer.observe(popupPortal, { childList: true, subtree: true });

		return () => {
			observer.disconnect();
		};
	}, []);

	return portalContainer
		? ReactDOM.createPortal(
				// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
				<div
					style={{ top }}
					{...mouseMoveHandlers}
					css={[popupPanelStyles, needTransition && popupTransition]}
				>
					<VisuallyHidden id="toolbar-alert">{formatMessage(i18n.toolbarAlert)}</VisuallyHidden>
					<ButtonContainer ref={popupRef} left={popupLeft} data-testid="highlightActionsPopup">
						{children}
					</ButtonContainer>
				</div>,
				portalContainer,
			)
		: null;
};
