import { isFedRamp } from '@atlassian/atl-context';

import { AccessStatus, ConfluenceEdition, useSessionData } from '@confluence/session-data';

export const useIsAIIssueCreationEnabled = () => {
	/**
	 * isAdminHubAIEnabled is a misleading name, Confluence AI verified that it represents AI features being completely enabled/disabled
	 * see: https://atlassian.slack.com/archives/C07ANEYMCNQ/p1724704271391179?thread_ts=1724701017.443109&cid=C07ANEYMCNQ for more context
	 *  */
	const { edition, accessStatus, isAdminHubAIEnabled } = useSessionData();

	const hasLicensedConfluenceAccess =
		accessStatus === AccessStatus.LICENSED_USE_ACCESS ||
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;

	return (
		edition === ConfluenceEdition.PREMIUM &&
		hasLicensedConfluenceAccess &&
		isAdminHubAIEnabled &&
		!isFedRamp()
	);
};
